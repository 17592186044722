import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './AboutWallet.scss';
import {useAppSelector} from '../../../store/hooks.ts';

const AboutWalletPopup = () => {
  const state = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet,
  );
  return (
    <Popup modalID="aboutWallet">
      <div className="AboutWallet">
        <h2> Об этом кошельке</h2>
        <p>
          <span>BTC wallet: {state}</span>
          <br />
          {state === '1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx' ? (
            <>
              Этот кошелек проще и сумма меньше. <br />
              Запаролен в 2015г.
              <br /> Использовались такие "патерны" пароля:
              <br />
              25Vitaliya07Popova2011
              <br /> 25Vita07Popova2011
              <br /> Vitaliya25.07.2011Popova
              <br />
              25Popova07Vitaliya2011
              <br /> VitaliyaPopova2011.!
              <br /> VitaPopova2011.!
              <br />
              Хозяин кошелька забыл последовательность - поможем ему?
            </>
          ) : (
            <>
              Этот кошелек был создан в 2009 году, в то время биткойн не
              воспринимался всерьез. <br /> <br />
              Пароль от кошелька был утерян и теперь у каждого из участников
              есть возможность получить крупное вознаграждение. <br /> <br />
              Ваши донаты за попытки идут на обслуживание серверов и поддержку
              трафика.
            </>
          )}
        </p>
        <p>
          <span>ГАРАНТИРОВАННОЕ ВОЗНАГРАЖДЕНИЕ</span> <br />
          {state === '1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx' ? (
            <>
              30% от суммы кошелька подобравшему правильный пароль.
              <br /> 10% кошелька - будут распределены между всеми участниками
              подбора - в равных долях и количества введенных попыток.
            </>
          ) : (
            <>
              Лишь один человек, подобравший верный пароль, получит BTC в
              размере 300 000 долларов США. <br /> <br />
              Однако, каждый участник проекта также получит гарантированное
              вознаграждение. <br />
              <br />
              10% баланса кошелька будут распределены между всеми пользователями
              в равных долях.
            </>
          )}
        </p>
      </div>
    </Popup>
  );
};
export default AboutWalletPopup;
