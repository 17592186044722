import React from 'react';
import {useAppSelector} from '../store/hooks';

const TwitchPlayer = () => {
  const state = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet,
  );
  return (
    <>
      <iframe
        src={
          state === '18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB'
            ? 'https://vk.com/video_ext.php?oid=-227624578&id=456239018&hd=2&autoplay=1'
            : 'https://vk.com/video_ext.php?oid=-227624578&id=456239021&hd=2&autoplay=1'
        }
        width="100%"
        height="100%"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        title="vk"></iframe>
      {/* <TwitchEmbedVideo
        theme="dark"
        allowfullscreen="false"
        layout="video"
        channel="find_pass"
        width="100%"
        height="100%"
      /> */}
    </>
  );
};

export default TwitchPlayer;
