import React from 'react';
import Popup from '../../Popup/Popup.tsx';
import './changeWallet.scss';
import {useDispatch} from 'react-redux';
import {setCurrentBTCwallet} from '../../../store/reducers/userStateSlice.ts';
import {useAppSelector} from '../../../store/hooks.ts';

const ChangeWalletPopup = () => {
  const dispatch = useDispatch();
  const state = useAppSelector(
    (state) => state.userStateReducer.currentBTCwallet,
  );

  console.log(state);

  return (
    <Popup modalID="changeWallet">
      <div className="ChangeWallet">
        <p>Выберите кошелек для разблокировки:</p>
        <ul>
          <li
            className={
              state === '18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB' ? 'active' : ''
            }
            onClick={() =>
              dispatch(
                setCurrentBTCwallet('18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB'),
              )
            }>
            {state === '18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB' && 'Текущий'}
            <span className="sub_title">
              BTC wallet: 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB
            </span>
          </li>
          <li
            className={
              state === '1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx' ? 'active' : ''
            }
            onClick={() =>
              dispatch(
                setCurrentBTCwallet('1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx'),
              )
            }>
            {state === '1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx' && 'Текущий'}
            <span className="sub_title">
              BTC wallet: 1FJNKPW1Rgw7vTzeHB4US9exBjsKStozJx
            </span>
          </li>
        </ul>
      </div>
    </Popup>
  );
};

export default ChangeWalletPopup;
